/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getProfile,
  updateProfile,
  disconnectCalendar,
} from '../../core/store/common/common'
import { validateUpdateProfile } from '../../shared/constants/patterns'

import { Profile } from '../../shared/components/Layout/Profile'
import moment from 'moment-timezone'
import { getUserInfo } from '../../shared/constants/utilities'
import { useNavigate } from 'react-router-dom'
import { Button, CircularProgress, IconButton } from '@mui/material'
import globals from '../../shared/constants/global.constant'
import styles from '../../shared/components/Layout/Layout.module.scss'
import { useMediaQuery } from 'react-responsive'

export const ProfileResponsive = () => {
  const muaMobile = useMediaQuery({
    query: `(max-width: 480px)`,
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { common } = useSelector((state) => state)

  const {
    user,
    isSuccessSetCalendar,
    isSuccessUpdateUser,
    isLoadingUpdateUser,
    isLoadingUser,
  } = common

  const [forceUpdate, setForceUpdate] = useState(false)
  const [profileAction, setProfileAction] = useState('profile')
  const [userInfo, setUserInfo] = useState({
    IsActiveNotification: true,
    TimeZone: { Name: '', Offset: '' },
    Calendar: '',
    FirstName: '',
    LastName: '',
    Email: '',
    Id: '',
  })
  const [errorUserInfo, setErrorUserInfo] = useState({
    FirstName: {
      error: false,
    },
    LastName: {
      error: false,
    },
    TimeZone: {
      error: false,
    },
    Calendar: {
      error: false,
    },
    IsActiveNotification: {
      error: false,
    },
  })

  const userId = getUserInfo() && getUserInfo().id

  useEffect(() => {
    if (user) {
      const {
        FirstName,
        LastName,
        Email,
        Id,
        TimeZone,
        Calendar,
        IsActiveNotification,
      } = user
      setUserInfo({
        IsActiveNotification,
        TimeZone,
        Calendar: Calendar,
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        Id: Id,
      })
    }
  }, [user])

  useEffect(() => {
    if (isSuccessUpdateUser) {
      dispatch(getProfile(userId))
      setProfileAction('profile')
    }
  }, [isSuccessUpdateUser])

  useEffect(() => {
    if (isSuccessSetCalendar) {
      dispatch(getProfile(userId))
      navigate('/')
      setProfileAction('profile')
    }
  }, [isSuccessSetCalendar])

  const handleUpdate = () => {
    setProfileAction('update')
  }

  const handleCloseUpdate = () => {
    setProfileAction('profile')
  }

  const handleChange = (event) => {
    const { value, id } = event.target
    const newUserInfo = userInfo
    newUserInfo[id] = value
    setUserInfo(newUserInfo)

    const newErrorUserInfo = errorUserInfo
    newErrorUserInfo[id].error = false
    setErrorUserInfo(newErrorUserInfo)
    setForceUpdate(!forceUpdate)
  }

  const handleSelectCalendar = async (value) => {
    const newUserInfo = userInfo
    newUserInfo.Calendar = value === '1' ? 'Google' : 'Outlook'
    await setUserInfo(newUserInfo)
    dispatch(updateProfile(userId, userInfo))
    setForceUpdate(!forceUpdate)
  }

  const handleSelectNotification = (value) => {
    const newUserInfo = userInfo
    newUserInfo.IsActiveNotification = value
    setUserInfo(newUserInfo)
    setForceUpdate(!forceUpdate)
  }

  const handleSelectTimeZone = (selTimeZone) => {
    const newUserInfo = userInfo
    newUserInfo.TimeZone = {
      Name: selTimeZone.id,
      Offset: moment.tz(selTimeZone.id).format('Z'),
    }

    setUserInfo(newUserInfo)
    setForceUpdate(!forceUpdate)
  }

  const handleSave = () => {
    const validation = validateUpdateProfile.validate(userInfo, {
      abortEarly: false,
    })

    if (validation.error) {
      const newErrorUserInfo = errorUserInfo
      validation.error.details.forEach((ele) => {
        newErrorUserInfo[ele.context.label].error = true
        setErrorUserInfo(newErrorUserInfo)
        setForceUpdate(!forceUpdate)
      })
    } else {
      dispatch(updateProfile(userId, userInfo))
      setForceUpdate(!forceUpdate)
    }
  }

  const handleDisconnect = () => {
    dispatch(disconnectCalendar())
  }

  return (
    <div
      className={styles.wrapperProfile}
      style={{
        justifyContent: profileAction === 'profile' && 'center',
        alignItems: profileAction === 'profile' && 'center',
      }}
    >
      {isLoadingUser ? (
        <CircularProgress size="20px" color="light" />
      ) : (
        <>
          {profileAction === 'profile' ? (
            <>
              <div className={styles.userName}>
                {`${userInfo.FirstName} ${userInfo.LastName}`}
              </div>
              <div className={styles.companyName}>
                {getUserInfo().companyName}
              </div>
              <div className={styles.userEmail}>{userInfo.Email}</div>
              {userInfo.Calendar && (
                <div className={styles.userEmail}>
                  {`Calendar: ${userInfo.Calendar} Calendar`}
                </div>
              )}
              {userInfo.TimeZone?.Name && (
                <div className={styles.userEmail}>
                  {`Time zone: ${userInfo.TimeZone?.Name}`}
                </div>
              )}
              <div className={styles.wrapperUpdateButton}>
                <Button className={styles.updateButton} onClick={handleUpdate}>
                  <p>Edit Profile</p>
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className={styles.updateIcon}>
                <IconButton onClick={handleCloseUpdate}>
                  <img
                    src={`${globals.logosBasePath}close-icon.svg`}
                    alt="close"
                  />
                </IconButton>
              </div>
              <Profile
                isLoadingUpdateUser={isLoadingUpdateUser}
                handleSave={handleSave}
                handleDisconnect={handleDisconnect}
                handleChange={handleChange}
                handleSelectCalendar={handleSelectCalendar}
                handleSelectNotification={handleSelectNotification}
                handleSelectTimeZone={handleSelectTimeZone}
                userInfo={userInfo}
                errorUserInfo={errorUserInfo}
                muaMobile={muaMobile}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}
